








import {
  defineComponent
} from '@nuxtjs/composition-api';
import CmsBlocks from 'components/theme/Content/CmsBlocks.vue';

export default defineComponent({
  name: 'TopBar',
  props: {
    identifier: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: 'transparent'
    }
  },
  components: {
    CmsBlocks
  }
})
