


















import {
  defineComponent,
  PropType,
  useFetch,
  ref
} from '@nuxtjs/composition-api'
import { useCmsContent } from '@wemade-vsf/composables'
import { CmsBlock } from '@wemade-vsf/magento-api';
import { useConfigStore } from '@wemade-vsf/core';
import { CacheTagPrefix, useCache } from '@wemade-vsf/cache';

export default defineComponent({
  name: 'CmsBlocks',
  props: {
    identifiers: {
      type: [Array, String] as PropType<string[] | string>,
      default: () => []
    },
    isCmsContent: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CmsBlock: () => import('./CmsBlock.vue'),
    CmsContent: () => import('./CmsContent.vue')
  },
  setup (props) {
    const { loadBlocks } = useCmsContent()
    const blocks = ref<CmsBlock[]>([])
    const identifiers = Array.isArray(props.identifiers) ? props.identifiers : [props.identifiers]
    const renderer = props.isCmsContent ? 'CmsContent' : 'CmsBlock'
    const { addTags } = useCache();

    const configStore = useConfigStore();
    let identifiersToLoad: string[] = [];
    identifiers.forEach(identifier => {
      if (configStore.blockCache[identifier]) {
        blocks.value.push(configStore.blockCache[identifier]);
      } else {
        identifiersToLoad.push(identifier);
      }
    })

    useFetch(async() => {
      if (identifiersToLoad.length) {
        const result = await loadBlocks({ identifiers });
        blocks.value = [
          ...blocks.value,
          ...result
        ]
      }
      addTags(identifiers.map(b => {
        return {
          prefix: CacheTagPrefix.Block,
          value: b
        }
      }))
    })

    return {
      blocks,
      renderer
    }
  }
})
